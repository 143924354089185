<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'MarketData',
  data() {
    return {};
  },
  computed: {
    selectedTopic() {
      return this.$store.getters.obtainSelectedTopic;
    },
  },
};
</script>
